import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_input = _resolveComponent("a-input")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_a_select_option = _resolveComponent("a-select-option")!
  const _component_a_select = _resolveComponent("a-select")!
  const _component_a_time_picker = _resolveComponent("a-time-picker")!
  const _component_MonacoEditor = _resolveComponent("MonacoEditor")!
  const _component_a_form = _resolveComponent("a-form")!
  const _component_a_spin = _resolveComponent("a-spin")!
  const _component_a_modal = _resolveComponent("a-modal")!

  return (_openBlock(), _createBlock(_component_a_modal, {
    "confirm-loading": _ctx.confirmLoading,
    visible: _ctx.visible,
    "onUpdate:visible": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.visible) = $event)),
    maskClosable: false,
    onOk: _ctx.handleOk,
    onCancel: _ctx.closeModal,
    width: "980px"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_a_spin, { spinning: _ctx.spinning }, {
        default: _withCtx(() => [
          _createVNode(_component_a_form, {
            ref: "formRef",
            model: _ctx.formState,
            rules: _ctx.rules,
            "label-col": { span: 4 }
          }, {
            default: _withCtx(() => [
              _createVNode(_component_a_form_item, {
                label: "任务名",
                name: "name"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_input, {
                    value: _ctx.formState.name,
                    "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.formState.name) = $event)),
                    autocomplete: "off"
                  }, null, 8, ["value"])
                ]),
                _: 1
              }),
              _createVNode(_component_a_form_item, {
                label: "任务描述",
                name: "des"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_input, {
                    value: _ctx.formState.des,
                    "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.formState.des) = $event))
                  }, null, 8, ["value"])
                ]),
                _: 1
              }),
              _createVNode(_component_a_form_item, {
                label: "赋值逻辑标签",
                name: "tagId",
                autoLink: false
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_select, {
                    value: _ctx.formState.devId,
                    "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.formState.devId) = $event)),
                    "show-search": "",
                    style: {"width":"40%"},
                    onChange: _ctx.devSelectChange
                  }, {
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.devList, (item) => {
                        return (_openBlock(), _createBlock(_component_a_select_option, {
                          key: item.ID,
                          value: item.ID
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(item.Name), 1)
                          ]),
                          _: 2
                        }, 1032, ["value"]))
                      }), 128))
                    ]),
                    _: 1
                  }, 8, ["value", "onChange"]),
                  _createVNode(_component_a_select, {
                    onChange: _cache[3] || (_cache[3] = ()=>{_ctx.formRef.validateFields(['tagId'])}),
                    value: _ctx.formState.tagId,
                    "onUpdate:value": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.formState.tagId) = $event)),
                    "show-search": "",
                    style: {"width":"40%","margin-left":"10px"}
                  }, {
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tagList, (item) => {
                        return (_openBlock(), _createBlock(_component_a_select_option, {
                          key: item.ID,
                          value: item.ID
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(item.Name), 1)
                          ]),
                          _: 2
                        }, 1032, ["value"]))
                      }), 128))
                    ]),
                    _: 1
                  }, 8, ["value"])
                ]),
                _: 1
              }),
              _createVNode(_component_a_form_item, {
                label: "定时类型",
                name: "type"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_select, {
                    value: _ctx.formState.type,
                    "onUpdate:value": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.formState.type) = $event)),
                    style: {"width":"40%"},
                    onChange: _cache[6] || (_cache[6] = (e)=>{
                if(e == 1){
                  _ctx.formState.Num = 0
                }
                if(e == 2){
                  _ctx.formState.Num = 1
                }
              })
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_select_option, { value: 0 }, {
                        default: _withCtx(() => [
                          _createTextVNode("每天")
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_a_select_option, { value: 1 }, {
                        default: _withCtx(() => [
                          _createTextVNode("每周")
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_a_select_option, { value: 2 }, {
                        default: _withCtx(() => [
                          _createTextVNode("每月")
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_a_select_option, { value: 3 }, {
                        default: _withCtx(() => [
                          _createTextVNode("循环")
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }, 8, ["value"])
                ]),
                _: 1
              }),
              _createVNode(_component_a_form_item, {
                label: "选择时间",
                name: "time",
                autoLink: false
              }, {
                default: _withCtx(() => [
                  (_ctx.formState.type == 1)
                    ? (_openBlock(), _createBlock(_component_a_select, {
                        key: 0,
                        value: _ctx.formState.Num,
                        "onUpdate:value": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.formState.Num) = $event)),
                        style: {"width":"40%","margin-right":"10px"}
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_select_option, { value: 0 }, {
                            default: _withCtx(() => [
                              _createTextVNode("星期天")
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_a_select_option, { value: 1 }, {
                            default: _withCtx(() => [
                              _createTextVNode("星期一")
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_a_select_option, { value: 2 }, {
                            default: _withCtx(() => [
                              _createTextVNode("星期二")
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_a_select_option, { value: 3 }, {
                            default: _withCtx(() => [
                              _createTextVNode("星期三")
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_a_select_option, { value: 4 }, {
                            default: _withCtx(() => [
                              _createTextVNode("星期四")
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_a_select_option, { value: 5 }, {
                            default: _withCtx(() => [
                              _createTextVNode("星期五")
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_a_select_option, { value: 6 }, {
                            default: _withCtx(() => [
                              _createTextVNode("星期六")
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }, 8, ["value"]))
                    : _createCommentVNode("", true),
                  (_ctx.formState.type == 2)
                    ? (_openBlock(), _createBlock(_component_a_select, {
                        key: 1,
                        value: _ctx.formState.Num,
                        "onUpdate:value": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.formState.Num) = $event)),
                        style: {"width":"40%","margin-right":"10px"}
                      }, {
                        default: _withCtx(() => [
                          (_openBlock(), _createElementBlock(_Fragment, null, _renderList(31, (item) => {
                            return _createVNode(_component_a_select_option, {
                              key: item,
                              value: item
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(item) + "号", 1)
                              ]),
                              _: 2
                            }, 1032, ["value"])
                          }), 64))
                        ]),
                        _: 1
                      }, 8, ["value"]))
                    : _createCommentVNode("", true),
                  _createVNode(_component_a_time_picker, {
                    value: _ctx.formState.time,
                    "onUpdate:value": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.formState.time) = $event)),
                    "value-format": "HH:mm:ss"
                  }, null, 8, ["value"])
                ]),
                _: 1
              }),
              _createVNode(_component_a_form_item, {
                label: "JS脚本字符串",
                name: "JSString"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_MonacoEditor, {
                    automaticLayout: true,
                    style: {"width":"99%","height":"160px"},
                    codes: _ctx.formState.JSString,
                    "onUpdate:codes": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.formState.JSString) = $event))
                  }, null, 8, ["codes"])
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["model", "rules"])
        ]),
        _: 1
      }, 8, ["spinning"])
    ]),
    _: 1
  }, 8, ["confirm-loading", "visible", "onOk", "onCancel"]))
}