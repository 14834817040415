
import {defineComponent, UnwrapRef, ref, reactive, nextTick} from "vue";
import {message} from 'ant-design-vue';
import {
  addTask,
  editTask,
  getDevList,
  getDevLogicTagList,
  getTask
} from "@/api/api_x2server";
import MonacoEditor from "@/components/MonacoEditor/MonacoEditor.vue";

export default defineComponent({
  components:{MonacoEditor},
  setup(props: any,context) {
    const confirmLoading = ref<boolean>(false)
    const visible = ref<boolean>(false)
    const spinning = ref<boolean>(false)
    const formRef = ref()
    const formState: UnwrapRef<any> = reactive({
      id: '',
      name: '',
      des: '',
      devId: '',
      tagId: '',
      type: 0,
      Num: 1,
      time: "00:00:00",
      JSString: "",
    });
    const rules = ref<any>({
      name: [
        {required: true, message: '请输入任务名', trigger: 'blur'},
      ],
      tagId: [
        {required: true, message: '请选择赋值逻辑标签', type: "number"},
      ],
      type: [
        {required: true,type:"number"},
      ],
      time: [
        {required: true, message: '请选择时间'},
      ],
      JSString: [
        {required: true, message: '请输入JS脚本字符串', trigger: 'change'},
      ],
    })
    const devList = ref<any>()
    const tagList = ref<any>()
    const actionList = ref<any>()
    let DataTaskOrg = ""

    const init = async (data: any) => {
      visible.value = true
      spinning.value = true
      getAllDev()
      DataTaskOrg = data.DataTaskOrg;
      if(data?.ID){
        const res: any = await getTask({id:data.ID})
        formState.id = res.ID;
        let info = res.TaskInfo;
        let item = res.TaskInfo.Item.Item;
        formState.name = info.Name;
        formState.des = info.Des;
        formState.devId = info.DevID;
        formState.JSString = item.JSString;
        formState.type = item.Type;
        formState.time = item.TimeInfo.Time;
        formState.Num = item.TimeInfo.Num;
        await devSelectChange();
        formState.tagId = info.TagID;
      }
      spinning.value = false
    }

    const getAllDev = async () => {
      const res: any = await getDevList();
      devList.value = res.Items
    }

    const handleOk = () => {
      formRef.value
          .validate()
          .then(async () => {
            confirmLoading.value = true;
            let res: any;
            let data: any = {
              Type:1,
              Interval: 200,
              DataTaskOrg,
              Name:formState.name,
              Des:formState.des,
              DevID:formState.devId,
              TagID:formState.tagId,
              Item:{
                Type: 4,
                Item:{
                  Type: formState.type,
                  JSString:formState.JSString,
                  TimeInfo:{
                    Time:formState.time,
                  }
                }
              }
            }
            if(formState.type == 1 || formState.type == 2){
              data.Item.Item.TimeInfo.Num = formState.Num
            }
            if (formState.id) {
              res = await editTask({
                ID: formState.id,
                TaskInfo:data
              })
            } else {
              res = await addTask(data)
            }
            confirmLoading.value = false;
            if (res.Result.toUpperCase() == "OK") {
              if (formState.id) {
                message.success("修改成功！")
              } else {
                message.success("添加成功！")
              }
              closeModal();
              context.emit("success")
            }
          })
    }
    const closeModal = () => {
      visible.value = false
      formRef.value.resetFields()
      formState.id = ""
      formState.devId = ""
    };
    const devSelectChange = async () => {
      const res: any = await getDevLogicTagList({devId:formState.devId});
      tagList.value = res.Items;
      formState.tagId = ""
    }
    return {
      confirmLoading,
      visible,
      spinning,
      handleOk,
      closeModal,
      formRef,
      formState,
      rules,
      init,
      devList,
      tagList,
      actionList,
      devSelectChange,
    }
  }
})
