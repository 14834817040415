
import {defineComponent, ref, reactive, UnwrapRef, createVNode, nextTick, onMounted} from "vue";
import analysisTask from "@/views/task/modules/analysisTask.vue";
import monitorTaskModal from "@/views/task/modules/monitorTaskModal.vue";
import timeTaskModal from "@/views/task/modules/timeTaskModal.vue";
import type {TableProps} from 'ant-design-vue';
import {
  addDataTaskGroup, addTaskFromCSVStream, creatTaskCSVStream,
  delDataTaskGroup, delTag, delTaskList, downloadLogicTagUrlList,
  editDataTaskGroup,
  getDataTaskOrgTree, getTaskList,
} from "@/api/api_x2server";
import {TreeDataItem} from 'ant-design-vue/es/tree/Tree';
import type {TreeProps} from 'ant-design-vue';
import {
  ExclamationCircleOutlined,
  AppstoreOutlined,
} from '@ant-design/icons-vue';
import {Modal, message} from 'ant-design-vue';
import {dateFormat} from "@/utils/common";

interface FormState {
  Name: string;
}

const columns = [
  {
    title: '任务ID',
    dataIndex: 'ID',
    width: 80
  },
  {
    title: '任务名称',
    key: 'name',
  },
  {
    title: '说明',
    key: 'des',
  },
  {
    title: '任务类型',
    key: "type"
  },
  {
    title: '运行状态',
    key: "state"
  },
  {
    title: '操作',
    align: 'center',
    width: 180,
    key: 'operation',
  }
]
export default defineComponent({
  components: {timeTaskModal, monitorTaskModal, analysisTask},
  setup() {
    const pageType = ref<number>(1)
    const sKeys = ref<(string | number)[]>([''])
    const exKeys = ref<any>([''])
    const currentNode = ref<any>([''])
    const selectParent = ref<any>("")
    const treeData = ref<TreeDataItem[]>([{
      title: "全部分组",
      key: '',
    }]);
    const modalTitle = ref<string>("");
    const visible = ref<boolean>(false);
    const formState: UnwrapRef<FormState> = reactive({
      Name: '',
    });
    const rules = ref<any>({
      Name: [
        {required: true, message: '请输入名称', trigger: 'blur'},
      ],
    });
    const confirmLoading = ref<boolean>(false);
    const formRef = ref();
    const dataSource = ref<any>([])
    const pagination = ref<any>({
      total: 0,
      current: 1,
      pageSize: 10,
    })
    const loading = ref<boolean>(false)
    const mtmRef = ref()
    const timeTaskRef = ref()
    const analysisTaskRef = ref()
    const myLeafIcon = createVNode(AppstoreOutlined)

    onMounted(() => {
      getList()
    })

    const onLoadData = async (treeNode: any) => {
      let parent: string;
      if (treeNode.dataRef.key === '') {
        parent = ""
      } else if (treeNode.dataRef.parent) {
        parent = treeNode.dataRef.parent + "/" + treeNode.dataRef.title
      } else {
        parent = treeNode.dataRef.title
      }
      const children: any = await getTreeChildren(parent);
      treeNode.dataRef.children = children
      if (children.length == 0) {
        treeNode.dataRef.switcherIcon = myLeafIcon;
        treeNode.dataRef.isLeaf = true;
      }
    }
    const getTreeChildren = async (parent: any) => {
      const res: any = await getDataTaskOrgTree({
        parent
      });
      return res.map((e: any) => {
        let tree: any = {
          title: e.Name,
          key: e.ID,
          parent: parent,
          children: []
        }
        if (e.HasChild === false) {
          tree.isLeaf = true
          tree.switcherIcon = myLeafIcon;
        }
        return tree
      })
    }
    const onSelect: TreeProps['onSelect'] = async (selectedKeys: (string | number)[], info: any) => {
      currentNode.value = info.node
      if(selectedKeys.length == 0){
        return
      }
      sKeys.value = selectedKeys
      if (info.node.dataRef.parent != undefined) {
        selectParent.value = info.node.dataRef.parent != "" ?
            info.node.dataRef.parent + "/" + info.node.dataRef.title : info.node.dataRef.title
      } else {
        selectParent.value = ""
      }
      nextTick(() => {
        pagination.value.current = 1
        getList();
      })
    };
    const onExpand = async (expandedKeys: string[]) => {
      exKeys.value = expandedKeys
    };
    const onContextMenuClick = (menuKey: string, node: any) => {
      if (menuKey === "1") {
        modalTitle.value = "新增组织"
        visible.value = true;
      } else if (menuKey === "2") {
        modalTitle.value = "修改组织"
        formState.Name = node.dataRef.title
        visible.value = true;
      } else if (menuKey === "3") {
        Modal.confirm({
          title: () => '是否删除该组织?',
          icon: () => createVNode(ExclamationCircleOutlined),
          okText: () => '是',
          okType: 'danger',
          cancelText: () => '否',
          async onOk() {
            const res: any = await delDataTaskGroup({
              path: node.dataRef.parent != '' ?
                  node.dataRef.parent + "/" + node.dataRef.title : node.dataRef.title
            })
            if (res.Result.toUpperCase() == "OK") {
              message.success("删除成功！")
              removeNode(treeData.value, node.key)
              sKeys.value = ['']
              getList();
            }
          },
        });
      }
    };
    const removeNode = (data: any, treeKey: string, parent?: any) => {
      data.forEach((key: any, index: number) => {
        if (key.key === treeKey) {
          data.splice(index, 1)
          if (parent != null && data.length === 0) {
            parent.isLeaf = true
            parent.switcherIcon = myLeafIcon;
          }
          return
        }
        if (key.children) {
          removeNode(key.children, treeKey, key)
        }
      })
    }
    const visibleChange = (visible: boolean, node: any) => {
      if (visible) {
        let a: any = document.getElementsByClassName(node.key + "_myTree")[0]
        a.click()
      }
    };
    const getNodeParent = () => {
      let node = currentNode.value;
      let parent: string;
      if (node && node.dataRef.parent != null) {
        parent = node.dataRef.parent != '' ? node.dataRef.parent + "/" + node.dataRef.title : node.dataRef.title
      } else {
        parent = ""
      }
      return parent
    }
    const handleOk = () => {
      formRef.value
          .validate()
          .then(async () => {
            confirmLoading.value = true;
            let res: any;
            let node = currentNode.value;
            let parent: string = getNodeParent()
            if (modalTitle.value == "新增组织") {
              res = await addDataTaskGroup({
                parent,
                ...formState
              })
            } else {
              res = await editDataTaskGroup({
                parent,
                ...formState
              })
            }
            confirmLoading.value = false;
            if (res.Result.toUpperCase() == "OK") {
              if (modalTitle.value == "新增组织") {
                if (node.isLeaf) {
                  node.dataRef.isLeaf = false
                  delete node.dataRef.switcherIcon
                  if (node.loaded) {
                    node.dataRef.children.push({
                      title: formState.Name,
                      key: formState.Name,
                      parent: parent,
                      children: [],
                      isLeaf: true,
                      switcherIcon: myLeafIcon,
                    })
                  } else {
                    nextTick(() => {
                      let a: any = document.getElementsByClassName(node.dataRef.key + '_myTree')[0];
                      a.parentNode.parentNode.parentNode.children[1].click()
                    })
                  }
                } else {
                  if (!node.expanded) {
                    let a: any = document.getElementsByClassName(node.dataRef.key + '_myTree')[0];
                    a.parentNode.parentNode.parentNode.children[1].click()
                  }
                  if (node.loaded) {
                    node.dataRef.children.push({
                      title: formState.Name,
                      key: formState.Name,
                      parent: parent,
                      children: [],
                      isLeaf: true,
                      switcherIcon: myLeafIcon,
                    })
                  }
                }
                message.success("添加成功！")
              } else {
                node.dataRef.title = formState.Name
                message.success("修改成功！")
              }
              closeModal();
            }
          })
    }
    const closeModal = () => {
      formRef.value.resetFields()
      visible.value = false;
    };
    const customRequest = async (info: any) => {
      const res: any = await addTaskFromCSVStream(info.file);
      if (res.Result.toUpperCase() == "OK") {
        getList()
        message.success("导入成功！")
      }
    }
    const getList = async () => {
      loading.value = true
      let res: any = await getTaskList({
        pageSize: pagination.value?.pageSize,
        start: (pagination.value?.current - 1) * pagination.value?.pageSize,
        parent: selectParent.value,
      })
      dataSource.value = res.Items
      pagination.value!.total = res.TotalCount
      loading.value = false
    }
    const handleTableChange: TableProps['onChange'] = (
        pag: any,
    ) => {
      pagination.value = pag
      getList()
    };
    const toDelete = (id: any) => {
      Modal.confirm({
        title: () => '是否删除该任务?',
        icon: () => createVNode(ExclamationCircleOutlined),
        okText: () => '是',
        okType: 'danger',
        cancelText: () => '否',
        async onOk() {
          const res: any = await delTaskList({id})
          if (res.Result.toUpperCase() == "OK") {
            message.success("删除成功！")
            getList();
          }
        },
      });
    }
    const downloadTaskFile = async () => {
      let res: any = await creatTaskCSVStream();
      let url = window.URL.createObjectURL(new Blob([res]))
      let a = document.createElement('a')
      a.style.display = 'none'
      a.href = url
      a.setAttribute('download', "TXT_" + dateFormat(new Date()) + ".txt")
      document.body.appendChild(a)
      a.click() //执行下载
      window.URL.revokeObjectURL(a.href) //释放url
      document.body.removeChild(a)
    }
    const toAnalysisTaskView = () => {
      pageType.value = 2;
      analysisTaskRef.value.init({
        DataTaskOrg: selectParent.value
      })
    }
    const openTimeModal = () => {
      modalTitle.value = "新增定时任务"
      timeTaskRef.value.init({
        DataTaskOrg: selectParent.value
      })
    }
    const openMonitorModal = () => {
      modalTitle.value = "新增监控任务"
      mtmRef.value.init({
        DataTaskOrg: selectParent.value
      })
    }
    const toEditTask = (record: any) => {
      if (record.TaskInfo.Type === 0) {
        pageType.value = 2;
        analysisTaskRef.value.init({
          ID: record.ID,
          DataTaskOrg: selectParent.value
        })
      }
      if (record.TaskInfo.Type === 1) {
        modalTitle.value = "修改定时任务"
        timeTaskRef.value.init({
          ID: record.ID,
          DataTaskOrg: selectParent.value
        })
      }
      if (record.TaskInfo.Type === 2) {
        modalTitle.value = "修改监控任务"
        mtmRef.value.init({
          ID: record.ID,
          DataTaskOrg: selectParent.value
        })
      }
    }
    return {
      pageType,
      sKeys,
      exKeys,
      treeData,
      modalTitle,
      visible,
      confirmLoading,
      formState,
      rules,
      formRef,
      columns,
      dataSource,
      pagination,
      loading,
      getList,
      onLoadData,
      onSelect,
      onExpand,
      onContextMenuClick,
      visibleChange,
      handleOk,
      closeModal,
      customRequest,
      handleTableChange,
      toDelete,
      downloadTaskFile,
      toAnalysisTaskView,
      openTimeModal,
      openMonitorModal,
      analysisTaskRef,
      timeTaskRef,
      mtmRef,
      toEditTask,
    }
  }
})
