export function dateFormat(time: Date) {
    if (!time || typeof time == "string") {
        return time
    }
    const y = time.getFullYear();
    const m = time.getMonth() < 9 ? '0' + (time.getMonth() + 1) : time.getMonth() + 1;
    const d = time.getDate() < 10 ? '0' + time.getDate() : time.getDate();
    const h = time.getHours() < 10 ? '0' + time.getHours() : time.getHours();
    const mm = time.getMinutes() < 10 ? '0' + time.getMinutes() : time.getMinutes();
    const s = time.getSeconds() < 10 ? '0' + time.getSeconds() : time.getSeconds();
    return y + '-' + m + '-' + d + ' ' + h + ':' + mm + ':' + s;
}
