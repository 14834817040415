
import {defineComponent, ref, reactive, UnwrapRef, createVNode, nextTick, onMounted} from "vue";
import {
  addTask,
  delDataTaskGroup,
  editTask,
  getDevList,
  getDevLogicTagList,
  getDevTagList,
  getTask
} from "@/api/api_x2server";
import {
  ExclamationCircleOutlined,
  PlusOutlined,
  MinusOutlined,
} from '@ant-design/icons-vue';
import {Modal,message} from 'ant-design-vue';

export default defineComponent({
  components: {ExclamationCircleOutlined, PlusOutlined, MinusOutlined},
  setup(props: any, context) {
    const spinning = ref<boolean>(false)
    const modalVisible = ref<boolean>(false)
    const visible = ref<boolean>(false)
    const title = ref<string>("")
    const devList = ref<any>()
    const tagList = ref<any>()
    const tagList2 = ref<any>()
    const boxList = ref<any>([])
    const formState: UnwrapRef<any> = reactive({
      id: '',
      name: '',
      des: '',
      Interval: '',
      InputSrc: '',
      devId: '',
      tagId: '',
    });
    const rules = ref<any>({
      name: [
        {required: true, message: '请输入任务名', trigger: 'blur'},
      ],
      Interval: [
        {required: true, message: '请输入轮询时间', trigger: 'blur', type: "number"},
      ],
      InputSrc: [
        {required: true, message: '请输入输入源', trigger: 'blur'},
      ],
      tagId: [
        {required: true, message: '请选择赋值逻辑标签', type: "number"},
      ],
    })
    const formState2: UnwrapRef<any> = reactive({
      devId: '',
      tagId: '',
    });
    const rules2 = ref<any>({
      devId: [
        {required: true, message: '请选择设备', trigger: 'change', type: "number"},
      ],
      tagId: [
        {required: true, message: '请选择标签', trigger: 'change'},
      ],
    })
    const boxType = ref<number>()
    const formRef = ref();
    const formRef2 = ref();
    const editValue = ref<any>([])
    let editIndex: any = null;

    let DataTaskOrg = ""

    const init = async (data: any) => {
      spinning.value = true
      getAllDev()
      DataTaskOrg = data.DataTaskOrg;
      if (data?.ID) {
        const res: any = await getTask({id: data.ID})
        formState.id = res.ID;
        let info = res.TaskInfo;
        formState.name = info.Name;
        formState.des = info.Des;
        formState.devId = info.DevID;
        formState.InputSrc = info.InputSrc;
        formState.Interval = info.Interval;
        res.TaskInfo.Items.forEach((key: any) => {
          boxList.value.push({
            type: key.Type,
            items: key.Item.map((e: any) => {
              let item: any = {}
              if (key.Type == 1 || key.Type == 2) {
                item.Symbol = e.Symbol
                if (e.Symbol == "Range") {
                  let value = e.Value;
                  let dhIndex = e.Value.indexOf(",");
                  let length = e.Value.length;
                  item.dy = value.substring(0, 1)
                  item.value1 = value.substring(1, dhIndex)
                  item.value2 = value.substring(dhIndex + 1, length - 1)
                  item.xy = value.substring(length - 1, length)
                } else {
                  item.value1 = e.Value
                }
              }
              if (key.Type == 2) {
                item.TargetType = e.TargetType
                item.TargetValue = e.TargetValue
              }
              if (key.Type == 3) {
                item.Type = e.Type
                item.Period = e.Period
              }
              return item;
            })
          })
        })
        await devSelectChange();
        formState.tagId = info.TagID;
      }
      spinning.value = false
    }
    const getAllDev = async () => {
      const res: any = await getDevList();
      devList.value = res.Items
    }
    const devSelectChange = async () => {
      const res: any = await getDevLogicTagList({devId: formState.devId});
      tagList.value = res.Items;
      formState.tagId = ""
    }
    const changeGetTag = async () => {
      const res: any = await getDevTagList({devId: formState2.devId});
      const res2: any = await getDevLogicTagList({devId:formState2.devId});
      tagList2.value = res.Items.concat(res2.Items);
      formState.tagId = ""
    }
    const backPage = () => {
      formRef.value.resetFields()
      formState.id = ""
      formState.devId = ""
      boxList.value = []
      context.emit("backPage")
    }
    const modalOk = () => {
      formRef2.value
          .validate()
          .then(() => {
            let dom: any = document.getElementById("InputSrc");
            let selectionStart = dom.selectionStart;
            let selectionEnd = dom.selectionEnd;
            let str = "$" + devList.value.find((e: any) => e.ID == formState2.devId).Name + "." + formState2.tagId + "$"
            if (selectionStart && selectionEnd) {
              formState.InputSrc = formState.InputSrc.substring(0, selectionStart) + str + formState.InputSrc.substring(selectionEnd, formState.InputSrc.length);
            } else {
              formState.InputSrc += str
            }
            modalCancel()
          })
    }
    const modalCancel = () => {
      modalVisible.value = false
      formRef2.value.resetFields()
    }
    const toEditTask = (item: any, index: number) => {
      editIndex = index;
      visible.value = true
      if (item.type == 1) {
        title.value = "数据过滤"
      }
      if (item.type == 2) {
        title.value = "数据转换"
      }
      if (item.type == 3) {
        title.value = "聚合运算"
      }
      boxType.value = item.type
      editValue.value = item.items
      visible.value = true
    }
    const toAddBox = (type: number) => {
      visible.value = true
      boxType.value = type
      if (type == 1) {
        title.value = "添加数据过滤"
      }
      if (type == 2) {
        title.value = "添加数据转换"
      }
      if (type == 3) {
        title.value = "添加聚合运算"
      }
      editValue.value.push({
        Symbol: "Range",
        dy: "(",
        xy: ")",
        TargetType: "Int32",
        Type: "0",
      })
    }

    const closeDrawer = () => {
      visible.value = false
      editValue.value = []
      editIndex = null;
    }
    const drawerCommit = () => {
      for (let i = 0; i < editValue.value.length; i++) {
        let item = editValue.value[i]
        if (boxType.value == 1 || boxType.value == 2) {
          if (item.value1 == null) {
            message.error("请输入值！")
            return
          }
          if (item.Symbol == "Range" && item.value2 == null) {
            message.error("请输入值！")
            return
          }
        }
        if (boxType.value == 2) {
          if (item.TargetValue == null) {
            message.error("请输入值！")
            return
          }
        }
        if (boxType.value == 3) {
          if (item.Period == null) {
            message.error("请输入值！")
            return
          }
        }
      }
      if (editIndex == null) {
        boxList.value.push({
          type: boxType.value,
          items: editValue.value
        })
      } else {
        boxList.value.splice(editIndex, 1, {
          type: boxType.value,
          items: editValue.value
        })
      }
      closeDrawer()
    }

    const commitAll = () => {
      // if (boxList.value.length == 0) {
      //   message.error("请添加处理逻辑")
      //   return
      // }
      formRef.value
          .validate()
          .then(async () => {
            let data: any = {
              DataTaskOrg,
              Name: formState.name,
              Des: formState.des,
              Interval: formState.Interval,
              InputSrc: formState.InputSrc,
              DevID: formState.devId,
              TagID: formState.tagId,
              Items: []
            }
            boxList.value.forEach((key: any) => {
              data.Items.push({
                "Type": key.type,
                "Item": key.items.map((e: any) => {
                  let item: any = {}
                  if (key.type == 1 || key.type == 2) {
                    item.Symbol = e.Symbol
                    if (e.Symbol == "Range") {
                      item.Value = e.dy + e.value1 + "," + e.value2 + e.xy;
                    } else {
                      item.Value = e.value1
                    }
                  }
                  if (key.type == 2) {
                    item.TargetType = e.TargetType
                    item.TargetValue = e.TargetValue
                  }
                  if (key.type == 3) {
                    item.Type = e.Type
                    item.Period = e.Period
                  }
                  return item
                })
              })
            })

            let res: any;
            if (formState.id) {
              res = await editTask({
                ID: formState.id,
                TaskInfo: data
              })
            } else {
              res = await addTask(data)
            }

            if (res.Result.toUpperCase() == "OK") {
              if (formState.id) {
                message.success("修改成功！")
              } else {
                message.success("添加成功！")
              }
              backPage();
            }
          })
    }
    const onContextMenuClick = (menuKey: string, index: any) => {
      Modal.confirm({
        title: () => '是否删除该逻辑?',
        icon: () => createVNode(ExclamationCircleOutlined),
        okText: () => '是',
        okType: 'danger',
        cancelText: () => '否',
        async onOk() {
          boxList.value.splice(index,1)
          message.success("删除成功！")
        },
      });
    }

    return {
      spinning,
      formState,
      rules,
      formState2,
      rules2,
      devList,
      tagList,
      tagList2,
      modalVisible,
      visible,
      title,
      formRef,
      formRef2,
      boxList,
      boxType,
      editValue,
      init,
      devSelectChange,
      changeGetTag,
      backPage,
      toEditTask,
      modalOk,
      modalCancel,
      toAddBox,
      closeDrawer,
      drawerCommit,
      commitAll,
      onContextMenuClick,
    }
  }
})
